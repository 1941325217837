export const areNumbersEqual = (a: unknown, b: unknown): boolean => {
    const numA = Number(a);
    const numB = Number(b);

    if (Number.isNaN(numA) || Number.isNaN(numB)) {
        return false;
    }

    return numA === numB;
};
