import React, { useEffect } from 'react';

// Types
import { VoteTypes, voteTypeOptions } from "types/VoteTypes";
// Styles
import SelectHostContainer from "./VoteType.styles";

type Props = {
    voteType: string;
    setVoteType: (value: VoteTypes) => void;
};

export default function SelectVoteType({
    voteType,
    setVoteType,
}: Readonly<Props>) {
    useEffect(() => {
        if (!voteType) {
            setVoteType(VoteTypes.FULL_VOTE);
        }
    }, [voteType, setVoteType]);

    const handleVoteTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value as VoteTypes;
        setVoteType(selectedValue);
    };

    return (
        <SelectHostContainer>
            <div className={"heading"}>
                <p>SELECT VOTE TYPE</p>
            </div>
                <div className={"dropdownRow"}>
                    <select value={voteType} onChange={handleVoteTypeChange}>
                        {voteTypeOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.value}
                            </option>
                        ))}
                    </select>
                </div>
        </SelectHostContainer>
    );
}