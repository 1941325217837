import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";

// Styles
import GrowthVotingDealContainer from "./GrowthVotingDeal.styles";

// Utils
import { ApiHandler } from "services/api_handler";
import { getDealName, getTeamNameByType, sortDealVotes} from "utils/growth_voting.utils";
import {formatDate} from "utils/date.utils";

// Material UI
import { Tooltip } from "@mui/material";

// Interfaces
import {DealTeamMember, FullDealDetails, GrowthQuestion} from "assets/interfaces/growth_voting";

// Components
import OpenCloseButton from "components/Screens/GrowthVoting/OpenCloseButton/OpenCloseButton";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import VoteDetailList from "components/Screens/GrowthVoting/VoteDetailList/VoteDetailList";
import CreateGrowthVotingModal from 'components/Screens/GrowthVoting/EditGrowthVotingModal/EditGrowthVotingModal';
import DealIcon from "../DealIcon/DealIcon";

// Context
import {useSettings} from "context/Settings.context";
import {useGrowthDeal} from "context/growth/GrowthDeal.context";
import { useAuth } from "context/Auth.context";


// Icons
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Enums
import {EnumGrowthVotingTeams} from "assets/enums/growth_voting";


const URL_DEAL_DETAIL = "api/growth_voting/deals";
const URL_PENDING_MY_VOTE = "api/growth_voting/pending-votes"


type LocationState = {
    deal?: FullDealDetails;
}


const GrowthDealDetail = () => {
    const { setShowLoadingToast, setToast } = useSettings();
    const { dealDirty, setDealDirty, answersDirty } = useGrowthDeal();
    const { user } = useAuth();

    const [ deal, setDeal ]= useState<FullDealDetails|null>();
    const [showEditDeal, setShowEditDeal] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [missingMyVote, setMissingMyVote] = useState<boolean>(false);

    const history = useHistory();

    const id = window.location.pathname.split("/").pop();

    const fetchPendingMyVote = async () => {
        let url = URL_PENDING_MY_VOTE + "?user_id=" + user.id + "&deal_id=" + id;
        let response = await new  ApiHandler(url, setShowLoadingToast, setToast).list();

        const dealsWithVotes = response.data.filter((deal: FullDealDetails) => {return deal.votes.length > 0});

        const isMissingMyVote = (dealsWithVotes.length === 0) ? false : true;

        setMissingMyVote(isMissingMyVote);
    };

    const fetchDeal = async () => {
        if (!id) {
            throw ("Deal ID is required");
        }

        let url = `${URL_DEAL_DETAIL}/${id}`;

        let response = await new  ApiHandler(url, setShowLoadingToast, setToast).get();
        sortDealVotes(response.data);
        setDeal(response.data);
        setDealDirty(false);
        setOpen(response.data.open);
    };

    const updateDeal = async (deal: any) => {
        let url = `${URL_DEAL_DETAIL}/${deal.id}`;
        await new ApiHandler(url, setShowLoadingToast, setToast).put({deal: deal});
        setDealDirty(true);
    }

    const changeDealState = (state: boolean) => {
        if (!deal) return;

        updateDeal({id: deal.id, open: state});
    };

    const deleteDeal = async () => {
        let url = `${URL_DEAL_DETAIL}/${deal?.id}`;
        await new ApiHandler(url, setShowLoadingToast, setToast).delete();

        history.push(`/growth-voting/deals`)
    }

    const dealChanged = () => {
        setDealDirty(true);
    }

    useEffect(() => {
        fetchDeal();
        fetchPendingMyVote();
    }, []);

    useEffect(() => {
        if (!deal && dealDirty) return;
        fetchDeal();
    }, [dealDirty]);

    useEffect(() => {
        if (answersDirty) {
            fetchPendingMyVote();
        }
    }, [answersDirty]);

    return (
        <GrowthVotingDealContainer>
            {showEditDeal && (
                <CreateGrowthVotingModal
                    showModal={showEditDeal}
                    setShowModal={setShowEditDeal}
                    deal={deal ? deal : null}
                    dealSaved={dealChanged}
                />
            )}
            <div className={"head"}>
                <p  className={"title"}>
                <DealIcon deal={deal ? deal : null} /> {deal && getDealName(deal)}
                </p>
                <ButtonSmall
                    className={"btn-edit"}
                    icon={faPencil}
                    text={"Edit Deal"}
                    click={() => { setShowEditDeal(true)}}
                />
                <OpenCloseButton open={open}  changeHanlder={changeDealState} hoverText={"Open/Close Deal"} />
                <ButtonSmall
                    color="accent"
                    className={"btn-edit"}
                    icon={faTrash}
                    text={"Delete Deal"}
                    click={() => deleteDeal()}
                />
            </div>
            {missingMyVote &&
                <div className={"no-votes"}>
                    <div className={"title"}>Deal Missing my Votes</div>
                </div>
            }
            <div className={"details"}>
                {deal?.description && <p>{deal?.description}</p>}
                {deal?.company.name &&
                    <p>
                        <span className={"label-base label-primary"}>Company</span>
                        {deal?.company.name}
                        {deal?.company?.url &&
                            <a className={"link"} target='_blank' href={`https://${deal?.company.url}`}>{deal?.company.url}</a>
                        }
                    </p>
                }
                {deal?.funding_round && <p><span className={"label-base label-success"}>Milestone</span>{deal?.funding_round}</p>}
                <div key={"key-" + deal?.id} className={"teams"}>
                    {deal?.teams && deal?.teams.length > 0 &&
                        deal?.teams.map((team, index) => (
                            team.type != EnumGrowthVotingTeams.Vote &&
                            <div className={"team"} key={index} >
                                <span className={"label-base label-team"}>{getTeamNameByType(team.type)}</span>
                                {team.members.map((member: DealTeamMember, index) => (
                                    <span key={index} className={"member"}>
                                        {member.name}
                                    </span>
                                ))}
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={"created"}>Created at {deal?.created && formatDate(new Date(deal.created.substr(0,10)))}</div>

            <div className={"votes"}>
               <VoteDetailList key={"key-" + deal?.id}
                    dealId={deal?.id ? deal.id : null}
                    votes={deal?.votes? deal.votes : []}
                    teams={deal?.teams ? deal.teams : []}
                    open={deal?.open ? deal.open : false}
                    />
            </div>
        </GrowthVotingDealContainer>
    );
};
export default GrowthDealDetail;
