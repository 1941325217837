import { Divider, Grid } from "@mui/material";
import TextField from "components/Common/TextField/TextField";
import { useCallback } from "react";
import {
    accrualsFields,
    AccrualsValues,
} from "screens/DailyRecs/Inputs/Accruals/types";

// Styles
import AccrualInputFieldsContainer from "./AccrualInputFields.styles";
import { areNumbersEqual } from "utils/number.utils";

interface AccrualInputFieldsProps {
    handleChange: (value: number, name: string) => void;
    newAccrualsValues: any;
    currentAccrualsValues?: AccrualsValues | null;
    disabled?: boolean;
}

const AccrualInputFields = ({
    disabled,
    handleChange,
    newAccrualsValues,
    currentAccrualsValues,
}: AccrualInputFieldsProps) => {
    const isHighlighted = useCallback(
        (name: keyof AccrualsValues) => {
            if (currentAccrualsValues) {
                return !areNumbersEqual(currentAccrualsValues[name], newAccrualsValues[name]);
            }
            return false;
        },
        [currentAccrualsValues, newAccrualsValues]
    );

    return (
        <AccrualInputFieldsContainer>
            <>
                <p className={"inputTitle"}>DUBLIN FEES</p>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {accrualsFields.dublin_fees.map((input) => {
                        let name = input.name;
                        let label = input.label;
                        if (input.type === "input") {
                            return (
                                <Grid key={name} item xs={2} sm={4} md={4}>
                                    <label>{label}</label>
                                    <div className="textFieldDiv">
                                        <TextField
                                            placeholder={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            type={"formattedValue"}
                                            change={(e: number) =>
                                                handleChange(e, name)
                                            }
                                            value={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            disabled={disabled}
                                            isHighlighted={isHighlighted(name as keyof AccrualsValues)}
                                        />
                                    </div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>

                <p className={"inputTitle"}>DUBLIN DEDUCTIONS</p>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {accrualsFields.dublin_deductions.map((input) => {
                        let name = input.name;
                        let label = input.label;
                        if (input.type === "input") {
                            return (
                                <Grid key={name} item xs={2} sm={4} md={4}>
                                    <label>{input.label}</label>
                                    <div className="textFieldDiv">
                                        <TextField
                                            placeholder={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            type={"formattedValue"}
                                            change={(e: number) =>
                                                handleChange(e, name)
                                            }
                                            value={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            isHighlighted={isHighlighted(name as keyof AccrualsValues)}
                                        />
                                    </div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>

                <p className={"inputTitle"}>DELAWARE FEES</p>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {accrualsFields.delaware_fees.map((input) => {
                        let name = input.name;
                        let label = input.label;
                        if (input.type === "input") {
                            return (
                                <Grid key={name} item xs={2} sm={4} md={4}>
                                    <label>{input.label}</label>
                                    <div className="textFieldDiv">
                                        <TextField
                                            placeholder={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            type={"formattedValue"}
                                            change={(e: number) =>
                                                handleChange(e, name)
                                            }
                                            value={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            isHighlighted={isHighlighted(name as keyof AccrualsValues)}
                                        />
                                    </div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>

                <p className={"inputTitle"}>DELAWARE DEDUCTIONS</p>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {accrualsFields.delaware_deductions.map((input) => {
                        let name = input.name;
                        let label = input.label;
                        if (input.type === "input") {
                            return (
                                <Grid key={name} item xs={2} sm={4} md={4}>
                                    <label>{input.label}</label>
                                    <div className="textFieldDiv">
                                        <TextField
                                            placeholder={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            type={"formattedValue"}
                                            change={(e: number) =>
                                                handleChange(e, name)
                                            }
                                            value={
                                                newAccrualsValues[
                                                    name as keyof AccrualsValues
                                                ]
                                            }
                                            isHighlighted={isHighlighted(name as keyof AccrualsValues)}
                                        />
                                    </div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </>
        </AccrualInputFieldsContainer>
    );
};
export default AccrualInputFields;
