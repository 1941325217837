import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import TextFieldContainer from "./TextField.styles";

type Props = {
    placeholder: any;
    value: any;
    change: Function;
    disabled?: boolean;
    error?: boolean;
    type?: "text" | "number" | "formattedValue";
    // formattedValue is a custom type which adds commas into the number, but only allows numbers to be entered.
    // If you want to add normal text, use "text", or use "number" for a normal number.
    label?: string;
    displayIcon?: boolean;
    errorText?: string;
    step?: string;
    isHighlighted?: boolean;
};

const addCommas = (num: string): string => {
    const [integerPart, decimalPart] = num.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
};

export default function TextField({
    placeholder,
    value,
    change,
    disabled,
    error = false,
    type,
    label,
    displayIcon = true,
    errorText,
    step = "1",
    isHighlighted = false,
}: Props) {
    const [showErrors, setShowErrors] = useState<boolean | undefined>(error);
    const [inputValue, setInputValue] = useState<string>(String(value));

    useEffect(() => {
        setShowErrors(error);
    }, [error]);

    useEffect(() => {
        setInputValue(String(value));
    }, [value]);

    const handleChange = (newValue: string) => {
        const rawValue = newValue.replace(/,/g, '');
        const isValidValue = /^\-?\d*\.?\d*$/.test(rawValue);

        if (isValidValue) {
            setInputValue(rawValue);
            change(rawValue); // Send raw value to parent component
            setShowErrors(false);
        }
    };

    const getFormattedValue = () => {
        if (!inputValue) return '';

        const [integerPart, decimalPart] = inputValue.split('.');
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return decimalPart !== undefined
            ? `${formattedIntegerPart}.${decimalPart}`
            : formattedIntegerPart;
    };

    return (
        <TextFieldContainer>
            <label>{label}</label>
            <div
                className={
                    (showErrors ? "textField error" : "textField ") +
                    (disabled ? "disabled" : "") +
                    (isHighlighted ? "highlighted" : "")
                }
            >
                {type === "formattedValue" ? (
                    <input
                        type={'text'}
                        step={step}
                        placeholder={addCommas(String(placeholder))}
                        value={getFormattedValue()}
                        onChange={(e) => {
                            handleChange(e.target.value);
                        }}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        type={type}
                        step={step}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => {
                            change(e.target.value);
                            setShowErrors(false);
                        }}
                        disabled={disabled}
                    />
                )}

                {displayIcon && value && !disabled ? (
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        fixedWidth
                        onClick={() => change("")}
                    />
                ) : null}
            </div>
            {errorText && showErrors ? (
                <p className="errorText">{errorText}</p>
            ) : null}
        </TextFieldContainer>
    );
}
