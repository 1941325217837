import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 40px;
    max-height: 40px;
    width: fit-content;

    border-radius: 5px;
    padding: 0 25px;

    margin: 0 10px;

    user-select: none;
    cursor: pointer;

    .btnText {
        margin: 0;
        font-size: 18px;
        letter-spacing: 1.5px;
        white-space: nowrap;
        cursor: pointer;
    }

    .icon {
        margin-right: 10px;
    }

    &.primary {
        background-color: ${(props) => props.theme.primaryButtonBackground};

        .icon {
            color: ${(props) => props.theme.primaryButtonForeground};
        }

        .btnText {
            color: ${(props) => props.theme.primaryButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.primaryButtonBackgroundHover};
        }
    }

    &.confirm {
        background-color: ${(props) => props.theme.bqmqVoteScore1};

        .icon {
            color: ${(props) => props.theme.bqmqVoteScore1};
        }

        .btnText {
            color: ${(props) => props.theme.primaryButtonForeground};
        }

        :hover {
            background-color: ${(props) =>
                props.theme.confirmButtonBackgroundHover};
        }
    }

    &.secondary {
        background-color: ${(props) => props.theme.secondaryButtonBackground};

        .icon {
            color: ${(props) => props.theme.secondaryButtonForeground};
        }

        .btnText {
            color: ${(props) => props.theme.secondaryButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.secondaryButtonBackgroundHover};
        }
    }

    &.third {
        background-color: ${(props) => props.theme.thirdButtonBackground};

        .icon {
            color: ${(props) => props.theme.thirdButtonForeground};
        }

        .btnText {
            color: ${(props) => props.theme.thirdButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.thirdButtonBackgroundHover};
        }
    }

    &.accent {
        background-color: ${(props) => props.theme.accentButtonBackground};

        .icon {
            color: ${(props) => props.theme.accentButtonForeground};
        }

        .btnText {
            color: ${(props) => props.theme.accentButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.accentButtonBackgroundHover};
        }
    }

    &.flat {
        background-color: ${(props) => props.theme.flatButtonBackground};

        .icon {
            color: ${(props) => props.theme.flatButtonForeground};
        }

        .btnText {
            color: ${(props) => props.theme.flatButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.flatButtonBackgroundHover};
        }
    }

    &.disabled {
        background-color: ${(props) => props.theme.filterButtonBackground};
        cursor: auto;

        .icon {
            color: ${(props) => props.theme.filterButtonForeground};
        }

        .btnText {
            color: ${(props) => props.theme.filterButtonForeground};
        }
        :hover {
            background-color: ${(props) => props.theme.filterButtonBackground};
        }
        
        label {
            // Label inside button shouldn't be clickable when the whole button is disabled
            cursor: auto;
        }
    }
`;
