// @ts-nocheck
import { useEffect, useState } from "react";

// FontAwesome
import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import Review from "components/Screens/BQMQ/Setup/Review/Review";
import SelectAnalyst from "components/Screens/BQMQ/Setup/SelectAnalyst/SelectAnalyst";
import SelectCompany from "components/Screens/BQMQ/Setup/SelectCompany/SelectCompany";
import SelectDate from "components/Screens/BQMQ/Setup/SelectDate/SelectDate";
import SelectHost from "components/Screens/BQMQ/Setup/SelectHost/SelectHost";
import SelectUsers from "components/Screens/BQMQ/Setup/SelectUsers/SelectUsers";
import SelectVoteType from "components/Screens/BQMQ/Setup/VoteType/VoteType";

// Types
import { VoteTypes } from "types/VoteTypes"

// Utils
import { applyTimeZoneOffset } from "utils/common.utils";

// Styles
import { useBQMQ } from "context/BQMQ.context";
import CreateSessionContainer from "./CreateSession.styles";

export default function CreateSession() {
    const [tab, setTab] = useState<
        "company" | "chair" | "analyst" | "users" | "review" | ""
    >("company");
    const [company, setCompany] = useState(null);
    const [analyst, setAnalyst] = useState("");
    const [chair, setChair] = useState("");
    const [users, setUsers] = useState<BQMQUser[]>([]);
    const [date, setDate] = useState<Date | null>(null);
    const [voteType, setVoteType] = useState<VoteTypes | "">("");

    useEffect(() => {
        if (!voteType) {
            setVoteType(VoteTypes.FULL_VOTE);
        }
    }, [voteType])

    const { setMyVotes, analysts, companies, sessions } = useBQMQ();

    const [scheduledEvents, setScheduledEvents] = useState<
        ScheduledEvent[] | []
    >([]);
    const [eventArray, setEventArray] = useState<any[]>([]);

    const getUserList = async () => {
        setUsers(
            analysts
                .sort((a: any, b: any) => a.label.localeCompare(b.label))
                .map((user: any) => ({
                    name: user.label,
                    id: user.value,
                    active: false,
                    completed: false,
                    invited: true,
                    core: false,
                }))
        );
    };

    const getScheduledEvents = async () => {
        const scheduledEvents = sessions.map((session: any) => ({
            title: `${applyTimeZoneOffset(session.start_date, "HH:mm")}: ${
                session.company.name
            }`,
            start: session.date,
            end: session.date,
            allDay: true,
        }));

        date
            ? setEventArray([
                  ...scheduledEvents,
                  {
                      title: `> ${applyTimeZoneOffset(date, "HH:mm")}: ${
                          company ? company.label : "NEW SESSION"
                      }`,
                      start: date,
                      end: date,
                      allDay: true,
                  },
              ])
            : setEventArray(scheduledEvents);

        setScheduledEvents(scheduledEvents);
    };

    useEffect(() => {
        const existingVotes = localStorage.getItem("bqmq");
        if (existingVotes) {
            localStorage.removeItem("bqmq");
            setMyVotes({});
        }
        getUserList();
    }, []);

    useEffect(() => {
        getScheduledEvents();
    }, [company]);

    return (
        <CreateSessionContainer className={"pageContainer"}>
            <PageTitle title="CREATE NEW FOCUS LIST SESSION" />

            <Tabs
                defaultTab={tab}
                dontChangeUrl
                tabs={[
                    {
                        title: "COMPANY",
                        value: "company",
                        content: (
                            <SelectCompany
                                company={company}
                                setCompany={setCompany}
                            />
                        ),
                        icon: (
                            <FontAwesomeIcon
                                className={
                                    "tabIcon " + (company ? "complete" : "")
                                }
                                icon={company ? faCheckCircle : faTimesCircle}
                                fixedWidth
                            />
                        ),
                    },
                    {
                        title: "CHAIR",
                        value: "chair",
                        content: (
                            <SelectHost
                                chair={chair}
                                setChair={setChair}
                                users={users}
                                setUsers={setUsers}
                            />
                        ),
                        icon: (
                            <FontAwesomeIcon
                                className={
                                    "tabIcon " + (chair ? "complete" : "")
                                }
                                icon={chair ? faCheckCircle : faTimesCircle}
                                fixedWidth
                            />
                        ),
                    },
                    {
                        title: "PRESENTER",
                        value: "analyst",
                        content: (
                            <SelectAnalyst
                                analyst={analyst}
                                setAnalyst={setAnalyst}
                                users={users}
                                setUsers={setUsers}
                            />
                        ),
                        icon: (
                            <FontAwesomeIcon
                                className={
                                    "tabIcon " + (analyst ? "complete" : "")
                                }
                                icon={analyst ? faCheckCircle : faTimesCircle}
                                fixedWidth
                            />
                        ),
                    },
                    {
                        title: "ATTENDEES",
                        value: "users",
                        content: (
                            <SelectUsers
                                users={users}
                                setUsers={setUsers}
                                chair={chair}
                                analyst={analyst}
                            />
                        ),
                        icon: (
                            <FontAwesomeIcon
                                className={
                                    "tabIcon " +
                                    (users.some(
                                        (user: any) => user.core === true
                                    ) &&
                                    users.some(
                                        (user: any) => user.core === true
                                    )
                                        ? "complete"
                                        : "")
                                }
                                icon={
                                    users.some(
                                        (user: any) => user.core === true
                                    )
                                        ? faCheckCircle
                                        : faTimesCircle
                                }
                                fixedWidth
                            />
                        ),
                    },
                    {
                        title: "DATE",
                        value: "date",
                        content: (
                            <SelectDate
                                company={company}
                                date={date}
                                setDate={setDate}
                                eventArray={eventArray}
                                setEventArray={setEventArray}
                                scheduledEvents={scheduledEvents}
                            />
                        ),
                        icon: (
                            <FontAwesomeIcon
                                className={
                                    "tabIcon " + (date ? "complete" : "")
                                }
                                icon={date ? faCheckCircle : faTimesCircle}
                                fixedWidth
                            />
                        ),
                    },
                    {
                        title: "VOTE TYPE",
                        value: "vote",
                        content: (
                            <SelectVoteType
                                voteType={voteType}
                                setVoteType={setVoteType}
                            />
                        ),
                        icon: (
                            <FontAwesomeIcon
                                className={
                                    "tabIcon " + (chair ? "complete" : "")
                                }
                                icon={chair ? faCheckCircle : faTimesCircle}
                                fixedWidth
                            />
                        ),
                    },
                    {
                        title: "REVIEW & SAVE",
                        value: "review",
                        content: (
                            <Review
                                company={company}
                                chair={chair}
                                analyst={analyst}
                                users={users}
                                date={date}
                                factorID={voteType}
                            />
                        ),
                    },
                ]}
            />
        </CreateSessionContainer>
    );
}
