import React, { useEffect, useState } from "react";

// Styles
import DailyFlowsPendingSingOffContainer from "./DailyFlowsPendingSignOff.styles";

// Components
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";

// MaterialUI
import { useSettings } from "context/Settings.context";
import Tooltip from "@mui/material/Tooltip";
import {
    formatDateISO,
    getLastBusinessDay,
    isFutureDate,
    isPastDate,
    isSameDay,
} from "../../../../../utils/date.utils";
import { formatNumberFixDecimal } from "../../../../../utils/common.utils";
import {
    FlowType,
    PendingDailyFlow,
    PendingDailyFlowResponse,
    PendingDailyFlowResponseData,
} from "./DailyFlowsPendingSingOff.types";

const DailyFlowsPendingSingOff = () => {
    const { setToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(true);
    const [flows, setFlows] = useState<PendingDailyFlow[]>([]);
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [queryParams, setQueryParams] = useState("")

    const today = new Date();
    const yesterdayDt: Date = new Date(new Date().setDate(today.getDate() - 1));
    const lastBusinessDay: Date = getLastBusinessDay(yesterdayDt);

    const columns = [
        {
            id: 1,
            title: "Account name",
            sortable: false,
            value: "accountName",
            cell: (data: PendingDailyFlow) => (
                <TableCell data={data.accountName ? data.accountName : ""} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "Type",
            sortable: false,
            value: "type",
            cell: (data: PendingDailyFlow) => (
                <span className={["type-label", data.type].join(" ")}>
                    {data.type}
                </span>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "DATE",
            sortable: false,
            value: "flowDateDt",
            cell: (data: PendingDailyFlow) => (
                <TableCell
                    data={
                        data.flowDateDt
                            ? data.flowDateDt.toLocaleDateString()
                            : ""
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "VERSION",
            sortable: false,
            value: "type",
            cell: (data: PendingDailyFlow) => <TableCell data={data.version} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "REDEMPTION / SUBSCRIPTION",
            sortable: false,
            value: "redemptions",
            cell: (data: PendingDailyFlow) => (
                <TableCell data={formatNumberFixDecimal(data.redemptions)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "MANAGEMENT FEES",
            sortable: false,
            value: "manFees",
            cell: (data: PendingDailyFlow) => (
                <TableCell data={formatNumberFixDecimal(data.manFees)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "PERFORMANCE FEES",
            sortable: false,
            value: "perfFees",
            cell: (data: PendingDailyFlow) => (
                <TableCell data={formatNumberFixDecimal(data.perfFees)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "OTHER FEES",
            sortable: false,
            value: "other",
            cell: (data: PendingDailyFlow) => (
                <TableCell data={formatNumberFixDecimal(data.other)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 9,
            title: "SIGN OFF - 1",
            sortable: false,
            value: "signedOffBy1",
            cell: (data: PendingDailyFlow) => (
                <>
                    {data.signedOffBy1 && (
                        <Tooltip
                            title={
                                data.signedOffDt1
                                    ? data.signedOffDt1.toLocaleDateString()
                                    : "No date"
                            }
                        >
                            <span>{data.signedOffBy1}</span>
                        </Tooltip>
                    )}

                    {!data.signedOffBy1 && (
                        <Button
                            text="SIGN OFF"
                            color="primary"
                            click={() => {
                                signOff(data);
                            }}
                        />
                    )}
                </>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "SIGN OFF - 2",
            sortable: false,
            value: "signedOffBy2",
            cell: (data: PendingDailyFlow) => (
                <>
                    {data.signedOffBy2 && (
                        <Tooltip
                            title={
                                data.signedOffDt2
                                    ? data.signedOffDt2.toLocaleDateString()
                                    : "No date"
                            }
                        >
                            <span>{data.signedOffBy2}</span>
                        </Tooltip>
                    )}

                    {!data.signedOffBy2 && (
                        <Button
                            text="SIGN OFF"
                            color="primary"
                            click={() => {
                                signOff(data);
                            }}
                            disabled={!data.signedOffBy1}
                        />
                    )}
                </>
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const getData = async (queryParams: string) => {
        setLoading(true);
        setFlows([]);

        let url = `api/nav/user_inputs/daily_flows/pending/${queryParams}`;
        url += `&date_from=${formatDateISO(lastBusinessDay)}`;

        let response = await Database.get(url);

        if (response.ok) {
            let data: PendingDailyFlowResponseData = await response.json();
            setTotal(data.total);

            const flows = data.data
                .map(mapToPendingDailyFlow)
                .sort(dailyFlowComparator);
            setFlows(flows);
        }

        setLoading(false);
    };

    const mapToPendingDailyFlow = (
        d: PendingDailyFlowResponse
    ): PendingDailyFlow => {
        const flowDate = d.flow_date_dt ? new Date(d.flow_date_dt) : null;
        return {
            accountName: d.account_name,
            accountNumber: d.account_number,
            flowDateDt: flowDate,
            id: d.id,
            manFees: d.man_fees,
            other: d.other,
            perfFees: d.perf_fees,
            redemptions: d.redemptions,
            signedOffBy1: d.signed_off_by_1,
            signedOffBy2: d.signed_off_by_2,
            signedOffDt1: d.signed_off_dt_1
                ? new Date(d.signed_off_dt_1)
                : null,
            signedOffDt2: d.signed_off_dt_2
                ? new Date(d.signed_off_dt_2)
                : null,
            version: d.version,
            type: mapFlowType(flowDate),
            order: 10000, // as a default go to the back
        };
    };

    const mapFlowType = (flowDate: Date | null): FlowType | null => {
        if (!flowDate) return null;

        if (isSameDay(lastBusinessDay, flowDate)) return "current";
        if (isFutureDate(lastBusinessDay, flowDate)) return "future";
        if (isPastDate(lastBusinessDay, flowDate)) return "historic";

        return null;
    };

    const dailyFlowComparator = (a: PendingDailyFlow, b: PendingDailyFlow) => {
        if (a.flowDateDt && b.flowDateDt) {
            return b.flowDateDt.getTime() - a.flowDateDt.getTime();
        } else {
            // if no date send it to the back
            return -1;
        }
    };

    const signOff = async (flow: PendingDailyFlow) => {
        let response = await Database.patch(
            `api/nav/user_inputs/daily_flows/${flow.id}/signoff`
        );

        if (response.ok) {
            await response.json();
            setToast({
                type: "success",
                show: true,
                message: "Signed Off",
            });

            getData(queryParams);
        } else {
            let data = await response.json();

            let msg = "Something went wrong.";
            if (data.message === "ALREADY_SIGNED_OFF") {
                msg = "You already signed off";
            }

            setToast({
                type: "error",
                show: true,
                message: msg,
            });
        }
    };

    useEffect(() => {
        if (queryParams) {
            getData(queryParams);
        }
    }, [queryParams]);

    return (
        <DailyFlowsPendingSingOffContainer>
            <div data-cy="table" className="tableDiv">
                <Table
                    large
                    pagination
                    data={flows}
                    total={total}
                    columns={columns}
                    loading={loading}
                    defaultLimit={25}
                    filterQuery=""
                    changePageQuery={(queryParams: string) => setQueryParams(queryParams)}
                />
            </div>
        </DailyFlowsPendingSingOffContainer>
    );
};
export default DailyFlowsPendingSingOff;
