import colors from "assets/styles/colors";

// Component Themes
import menuTheme from "./menu.theme";
import buttonTheme from "./button.theme";
import tableTheme from "./table.theme";
import tabsTheme from "./tabs.theme";
import filterButtonTheme from "./filterButton.theme";
import profileTheme from "./profile.theme";
import modalTheme from "./modal.theme";
import homeListTheme from "./homeList.theme";
import bqmqTheme from "./bqmq.theme";
import adaHome from "./adaHome.theme";
import askAdaTheme from "./askAda.theme";
import labelTheme from "./label.theme";

export default {
    mainBackground: "white",

    homeBackground: "#e8e8e8",

    textPrimary: "black",

    pageTitleText: colors.textNavy,
    pageSubTitleText: colors.grey30,

    settingsMenuMainBackground: "white",
    settingsMenuHeaderBackground: colors.textNavy,
    settingsMenuHeaderButtonBackground: colors.textNavyDark,
    settingsMenuHeaderButtonBackgroundHover: colors.textTeal,
    settingsMenuHeaderText: "white",

    switchTrack: colors.textNavy,
    switchChecked: colors.textNavy,

    permissionsCheck: colors.textNavy,
    permissionsAdd: colors.accentGreen,
    permissionsDelete: colors.accentRed,

    fieldHighlightBorder: colors.accentBlue,
    fieldHighlightBackground: colors.teal30,
    fieldHighlightHoverBackground: colors.teal60,

    ...menuTheme,
    ...buttonTheme,
    ...tableTheme,
    ...tabsTheme,
    ...filterButtonTheme,
    ...profileTheme,
    ...modalTheme,
    ...homeListTheme,
    ...bqmqTheme,
    ...adaHome,
    ...askAdaTheme,
    ...labelTheme,
};
