import { useEffect, useState } from "react";
import { useBQMQ } from "context/BQMQ.context";
import VotingCell from "./VotingCell/VotingCell";
import VotingRowContainer from "./VotingRow.styles";
import queryString from 'query-string';

type Props = {
    question: string;
    questionID: string;
    previousVote?: any;
    agree?: boolean;
    disabled?: boolean;
    updateSectionCompletionStatus: () => void;
};

export default function VotingRow({
    question,
    questionID,
    previousVote,
    agree = false,
    disabled = false,
    updateSectionCompletionStatus,
}: Props) {

    const [numberSelected, setNumberSelected] = useState<number | string | null>(null);
    const { myVotes, setMyVotes } = useBQMQ();
    const [ userId, setUserId ] = useState<string>('');
    const formattedQuestion = question === "Fade" ? "Fade" : `${question}*`;
    const [formattedQuestionWithVote, setFormattedQuestionWithVote] = useState<string>("");
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const sessionId  = queryString.parse(window.location.search).session_id;

    useEffect(() => {
        if (numberSelected !== null) {
            setMyVotes((prevVotes: any) => {
                const updatedVotes = {
                    ...prevVotes,
                    [questionID]: numberSelected,
                };
                if (userId)
                    localStorage.setItem(`myVotes ${userId} ${sessionId}`, JSON.stringify(updatedVotes));
                return updatedVotes;
            });
        }
    }, [numberSelected]);

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            const userId = localStorage.getItem("gim-user-id") || '';
            setUserId(userId);
            return;
        }
        if (myVotes[questionID] !== numberSelected) {
            setNumberSelected(myVotes[questionID]);
        }
    }, [myVotes, questionID]);


    useEffect(() => {
        if (previousVote) {
            setFormattedQuestionWithVote(`(Prior Vote: ${previousVote})`);
        } else {
            setFormattedQuestionWithVote("");
        }
    }, [previousVote]);

    const handleSelectOption = (selected: string | number) => {
        if (!disabled) {
            setNumberSelected(selected);
            updateSectionCompletionStatus();
        }
    };

    return (
        <VotingRowContainer key={questionID}>
            <div className="questionCell">
                {formattedQuestion === "MQ**" ? "MQ*" : formattedQuestion}
                <br className="desktop-only" />
                {formattedQuestionWithVote}
            </div>
            
            {!agree && (
                <div className="votingCells">
                    {["1", "2", "3", "4", "5"].map((number) => (
                        <VotingCell
                            key={number}
                            number={number}
                            selected={myVotes[questionID]}
                            setSelected={handleSelectOption}
                            disabled={disabled}
                        />
                    ))}
                </div>
            )}

            {agree && (
                <>
                    {["AGREE", "NEUTRAL", "DISAGREE"].map((title) => (
                        <VotingCell
                            key={title}
                            number={title}
                            selected={myVotes[questionID]}
                            setSelected={handleSelectOption}
                            disabled={disabled}
                        />
                    ))}
                </>
            )}
        </VotingRowContainer>
    );
}