import React, { useEffect, useState } from "react";

// Styles
import DailyFlowsByAccountContainer from "./DailyFlowsByAccount.styles";

// Components
import Select from "components/Common/Select/Select";
import DateSelect from "components/Common/DateSelect/DateSelect";
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import AddDailyFlowModal from "components/Screens/DailyRecs/DailyFlowsInput/AddDailyFlowModal/AddDailyFlowModal";

// Utils
import * as Database from "utils/database.utils";

// Font Awesome
import {faArrowRight, faDownload, faPlus} from "@fortawesome/free-solid-svg-icons";

// MaterialUI
import { Divider, Grid } from "@mui/material";
import SignOff from "components/Screens/DailyRecs/Common/SignOff/SignOff";
import { useSettings } from "context/Settings.context";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import Tooltip from "@mui/material/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLastBusinessDay, isFutureDate, isPastDate, isSameDay} from "../../../../../utils/date.utils";
import {formatNumberFixDecimal} from "../../../../../utils/common.utils";

interface Account {
    gen_acct: string;
    account_name: string;
}

interface Flow {
    accountNumber: string,
    flowDateDt: Date | null,
    id: string,
    manFees: number,
    other: number,
    perfFees: number,
    redemptions: number,
    signedOffBy1: string,
    signedOffBy2: string,
    signedOffDt1: Date | null,
    signedOffDt2: Date | null,
    version: number,
    type: null|"current"|"future"|"historic"
    order: number,
}

// complicated to paginate a table if data fetched outside of the Table component, set high pagesize
const pageLimit = 200;

const DailyFlowsByAccount = () => {
    const { setShowLoadingToast, setToast } = useSettings();

    const [reload, setReload] = useState(0);
    const [filterQuery, setFilterQuery] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(true);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [flows, setFlows] = useState<Flow[]>([]);

    const [selectedAccount, setSelectedAccount] = useState<Account | null>(
        null
    );

    const [showAddDailyFlowModal, setShowAddDailyFlowModal] =
        useState<boolean>(false);

    const today = new Date()

    const yesterdayDt: Date = new Date(new Date().setDate(today.getDate() - 1));

    const lastBusinessDay: Date = getLastBusinessDay(yesterdayDt);

    const columns = [
        {
            id: 9,
            title: "Type",
            sortable: false,
            value: "type",
            cell: (data: any) => (
               <span className={["type-label", data.type].join(" ")}>{data.type}</span>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "DATE",
            sortable: false,
            value: "flowDateDt",
            cell: (data: any) => (
                <TableCell data={ (data.flowDateDt) ? data.flowDateDt.toLocaleDateString() : ""}/>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "VERSION",
            sortable: false,
            value: "type",
            cell: (data: any) => (
                <TableCell data={data.version} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 1,
            title: "REDEMPTION / SUBSCRIPTION",
            sortable: false,
            value: "redemptions",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.redemptions)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "MANAGEMENT FEES",
            sortable: false,
            value: "manFees",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.manFees)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "PERFORMANCE FEES",
            sortable: false,
            value: "perfFees",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.perfFees)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "OTHER FEES",
            sortable: false,
            value: "other",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.other)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "SIGN OFF - 1",
            sortable: false,
            value: "signedOffBy1",
            cell: (data: any) =>
                <>
                    {data.signedOffBy1 && (
                        <Tooltip title={(data.signedOffDt1) ? data.signedOffDt1.toLocaleDateString() : "No date"}>
                            <span>{data.signedOffBy1}</span>
                        </Tooltip>
                    )}

                    {!data.signedOffBy1 && (
                      <Button
                        text="SIGN OFF"
                        color="primary"
                        click={() => { signOff(data) }}
                      />
                    )}
                </>,
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "SIGN OFF - 2",
            sortable: false,
            value: "signedOffBy2",
            cell: (data: any) =>
                <>
                    {data.signedOffBy2 && (
                        <Tooltip title={(data.signedOffDt2) ? data.signedOffDt2.toLocaleDateString() : "No date"}>
                            <span>{data.signedOffBy2}</span>
                        </Tooltip>
                    )}

                    {!data.signedOffBy2 && (
                        <Button
                            text="SIGN OFF"
                            color="primary"
                            click={() => { signOff(data) }}
                            disabled={!data.signedOffBy1}
                        />
                    )}
                </>,
            show: true,
            fullDataRow: true,
        },
    ];

    const getAccounts = async () => {
        let response = await Database.get(`api/nav/lookups/accounts`);
        if (response.ok) {
            let data = await response.json();

            setAccounts(data.data);

            setSelectedAccount({
                gen_acct: data.data[0].gen_acct,
                account_name: data.data[0].account_name,
            });
        }
    };

    const getData = async () => {
        setLoading(true);
        setFlows([])

        let response = await Database.get(
            `api/nav/user_inputs/daily_flows/${selectedAccount?.gen_acct}/?pageSize=${pageLimit}`
        );

        if (response.ok) {
            let data: any = await response.json();

            let flows: Flow[] = []

            data.data.forEach((d: any) => {
               let flow: Flow = {
                    accountNumber: d.account_number,
                    flowDateDt: (d.flow_date_dt) ? new Date(d.flow_date_dt) : null,
                    id: d.id,
                    manFees: d.man_fees,
                    other: d.other,
                    perfFees: d.perf_fees,
                    redemptions: d.redemptions,
                    signedOffBy1: d.signed_off_by_1,
                    signedOffBy2: d.signed_off_by_2,
                    signedOffDt1:  (d.signed_off_dt_1) ? new Date(d.signed_off_dt_1) : null,
                    signedOffDt2: (d.signed_off_dt_2) ? new Date(d.signed_off_dt_2) : null,
                    version: d.version,
                    type: null,
                    order: 10000, // as a default go to the back
                }

                flows.push(flow)
            })

            setupFlowType(flows);
            setupFlowOrder(flows);

            setFlows(flows)
        }

        setLoading(false);
    };

    const setupFlowOrder = (flows: Flow[]) => {
        // order of the flows: future, current, historic
        flows.sort((a,b) => {
            if (a.flowDateDt && b.flowDateDt) {
                return b.flowDateDt.getTime() - a.flowDateDt.getTime();
            } else {
                // if no date send it to the back
                return -1
            }

        });
    };

    const setupFlowType = (flows: Flow[]) =>  {
        flows.forEach(flow => {
            if (!flow.flowDateDt) {
            } else if (isSameDay(lastBusinessDay, flow.flowDateDt)) {
                flow.type = "current";
            } else if (isFutureDate(lastBusinessDay, flow.flowDateDt,)) {
                flow.type = "future";
            } else if (isPastDate(lastBusinessDay, flow.flowDateDt)) {
                flow.type = "historic";
            }
        });
    }

    const accountChanged = (gen_acct: string) => {
        let account = accounts.find((a) => a.gen_acct === gen_acct) ?? null;
        setSelectedAccount(account);
    };

    const signOff =  async (flow: Flow) => {
        let response = await Database.patch(
            `api/nav/user_inputs/daily_flows/${flow.id}/signoff`,
        );

        if (response.ok) {
              let data = await response.json();

              setToast({
                type: "success",
                show: true,
                message: "Signed Off",
            });

            getData()
        } else {
            let data = await response.json()

            let msg = "Something went wrong."
            if (data.message == "ALREADY_SIGNED_OFF") {
                msg = "You already signed off"
            }

            setToast({
                type: "error",
                show: true,
                message: msg,
            });
        }
    }

    useEffect(() => {
        getData();
    }, [selectedAccount]);

    useEffect(() => {
        setFlows([]);
        getAccounts();
    }, []);

    return (
        <DailyFlowsByAccountContainer>
            {showAddDailyFlowModal && (
                <AddDailyFlowModal
                    setShowModal={setShowAddDailyFlowModal}
                    runUpdate={getData}
                />
            )}
            <Button
                text="CREATE DAILY FLOW"
                className="createBtn"
                icon={faPlus}
                click={() => setShowAddDailyFlowModal(!showAddDailyFlowModal)}
            />
            <div className="selectContainer">
                <div className="select">
                    <label>{"Account Name"}</label>
                    <Select
                        filterName="Account"
                        searchable
                        value={selectedAccount ? selectedAccount.gen_acct : ""}
                        setValue={(e: any) => accountChanged(e)}
                        options={accounts
                            .sort((a, b) =>
                                a.account_name.localeCompare(b.account_name)
                            )
                            .map((account) => {
                                return {
                                    value: account?.gen_acct,
                                    label: account?.account_name,
                                };
                            })}
                    />
                </div>
            </div>
            <Divider />

            <div data-cy="table" className="tableDiv">
                <Table
                    reload={reload}
                    data={flows}
                    columns={columns}
                    filterQuery={filterQuery}
                    large
                    loading={loading}
                />
            </div>
        </DailyFlowsByAccountContainer>
    );
};
export default DailyFlowsByAccount;
