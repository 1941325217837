import { useEffect, useState } from "react";

// Styles
import InputTabContainer from "./InputTab.styles";

// Components
import SignOff from "components/Screens/DailyRecs/Common/SignOff/SignOff";
import Button from "components/Common/Button/Button";

// Material UI
import Select from "components/Common/Select/Select";
import UpdateAccrualModal from "components/Screens/DailyRecs/Accruals/UpdateAccrualModal/UpdateAccrualModal";

// Utils
import * as Database from "utils/database.utils";
import { useSettings } from "context/Settings.context";
import { uppercaseFirstLetter } from "utils/common.utils";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
    AccrualsValues,
} from "screens/DailyRecs/Inputs/Accruals/types";
import AccrualInputFields from "../AccrualInputFields/AccrualInputFields";

interface InputTabProps {
    noSignOff?: boolean;
    accrualsUrl?: string;
}

const InputTab = ({ noSignOff = false, accrualsUrl }: InputTabProps) => {
    const { setShowLoadingToast, setToast } = useSettings();

    const [noData, setNoData] = useState<boolean>(false);
    const [hasEdited, setHasEdited] = useState<boolean>(false);
    const [selectedRegion, setSelectedRegion] = useState<string>("global");
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [accrualsValues, setAccrualsValues] = useState<AccrualsValues>({
        fund_admin: 0,
        directors_fee_eur: 0,
        directors_meeting_expenses: 0,
        auditors_fee_eur: 0,
        other_professional_fees_pwc_uk_rfs_gbp: 0,
        legal_fees_annual: 0,
        sub_custody_fee_trustee: 0,
        custody_fee_safekeeping: 0,
        misc_vat_tt_charges: 0,
        ifrsa_levy_eur: 0,
        offshore_registration: 0,
        liability_insurance_gbp_dublin: 0,
        prior_month_nav: 0,

        // Deductions
        ta_fee_nt: 0,
        tax_reporting_services_deloitte: 0,
        incentive_fee_calculation_deloitte: 0,
        custody_fee_nt: 0,
        audit_fee_kpmg: 0,
        fund_admin_nt: 0,
        legal_fee: 0,
        liability_insurance_gbp_delaware: 0,

        // Delaware Deductions
        outstanding_accrual_carried_forward_delaware: 0,
        deductions_to_accrued_expenses_delaware: 0,
        management_fee_paid_delaware: 0,

        // Dublin Deductions
        outstanding_accrual_carried_forward_dublin: 0,
        deductions_to_accrued_expenses_dublin: 0,
        management_fees_dublin: 0,

        fund_region: "",
    });
    const [currentAccrualsValues, setCurrentAccrualsValues] =
        useState<AccrualsValues | null>(null);
    const [newAccrualsValues, setNewAccrualsValues] =
        useState<any>(accrualsValues);

    const proposedValuesUrl = `api/nav/user_inputs/accruals/${selectedRegion}/`;
    const currentValuesUrl = `${proposedValuesUrl}?signed_off=true`;

    const getData = async () => {
        try {
            setNoData(false);
            setShowLoadingToast({
                show: true,
                message: "LOADING",
            });
            let response = await Database.get(
                noSignOff ? currentValuesUrl : proposedValuesUrl
            );
            if (!response.ok) {
                setNoData(true);
            }
            if (response.ok) {
                let data = await response.json();
                setAccrualsValues(data);
                setNewAccrualsValues(data);
            }
            if (!noSignOff) {
                let response = await Database.get(currentValuesUrl);
                if (response.ok) {
                    let data = await response.json();
                    setCurrentAccrualsValues(data);
                } else {
                    setCurrentAccrualsValues(null);
                }
            }
        } catch (err) {
            setShowLoadingToast({
                show: false,
                message: "",
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const handleChange = (value: number | string, name: string) => {
        setNewAccrualsValues({
            ...newAccrualsValues,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        setShowLoadingToast({
            show: true,
            message: "UPDATING",
        });
        for (const [key, value] of Object.entries(newAccrualsValues)) {
            if (key !== "fund_region" && typeof value === "string") {
                newAccrualsValues[key as keyof AccrualsValues] =
                    parseFloat(value);
            }
        }
        const {
            id,
            signed_off_dt_1,
            signed_off_dt_2,
            signed_off_by_1,
            signed_off_by_2,
            version,
            ...filteredPostObj
        } = newAccrualsValues;
        newAccrualsValues["fund_region"] = selectedRegion;
        let response = await Database.post(
            "api/nav/user_inputs/accruals/",
            filteredPostObj
        );
        if (response.ok) {
            setToast({
                show: true,
                type: "success",
                message:
                    "Updated accruals data for " +
                    uppercaseFirstLetter(newAccrualsValues.fund_region),
            });
            getData();
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    //check to see wether user has edited the values
    useEffect(() => {
        if (
            JSON.stringify(newAccrualsValues) !== JSON.stringify(accrualsValues)
        ) {
            setHasEdited(true);
        } else if (
            JSON.stringify(newAccrualsValues) === JSON.stringify(accrualsValues)
        ) {
            setHasEdited(false);
        }
    }, [newAccrualsValues]);

    useEffect(() => {
        getData();
    }, [selectedRegion]);

    return (
        <InputTabContainer>
            <div style={{ marginTop: "20px" }}>
                {showUpdateModal && (
                    <UpdateAccrualModal
                        setShowModal={setShowUpdateModal}
                        runUpdate={getData}
                    />
                )}
                <div className="selectContainer">
                    <div className="select">
                        <label>{"Region"}</label>
                        <Select
                            filterName="Region"
                            value={selectedRegion}
                            setValue={setSelectedRegion}
                            options={["Asia", "Global"].map((region) => {
                                return {
                                    value: region.toLowerCase(),
                                    label: region,
                                };
                            })}
                        />
                    </div>
                </div>
            </div>
            {noData ? (
                <div className="noData">
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                    <p>{`No data found for ${uppercaseFirstLetter(
                        selectedRegion
                    )}`}</p>
                </div>
            ) : (
                <div className="inputContainerDiv">
                    {!noSignOff && (
                        <div className="topActionDiv">
                            <Button
                                className="saveButton"
                                color={hasEdited ? "primary" : "flat"}
                                disabled={!hasEdited}
                                text="SAVE INPUTS"
                                click={() => handleUpdate()}
                            />
                        </div>
                    )}
                    <AccrualInputFields
                        disabled={noSignOff}
                        handleChange={handleChange}
                        newAccrualsValues={newAccrualsValues}
                        currentAccrualsValues={currentAccrualsValues}
                    />
                    {!noSignOff && (
                        <div
                            style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                            }}
                        >
                            <SignOff
                                runUpdates={getData}
                                signOffUrl={`api/nav/user_inputs/accruals/${selectedRegion}/signoff`}
                                values={accrualsValues}
                            />
                        </div>
                    )}
                </div>
            )}
        </InputTabContainer>
    );
};
export default InputTab;
