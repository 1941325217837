import DailyFlowsContainer from './DailyFlows.styles'
import Tabs from "components/Common/Tabs/Tabs";
import DailyFlowsByAccount
  from "components/Screens/DailyRecs/DailyFlowsInput/DailyFlowsByAccount/DailyFlowsByAccount";
import DailyFlowsPendingSingOff
  from "components/Screens/DailyRecs/DailyFlowsInput/DailyFlowsPendingSignOff/DailyFlowsPendingSingOff";

const DailyFlows = () => {
  return (
    <DailyFlowsContainer>
      <p data-cy="navs-main-title" className={"sectionTitle"}>
        DAILY FLOWS
      </p>
      <Tabs
        defaultTab="by_account"
        tabs={[
          {
            value: "by_account",
            title: "BY ACCOUNT",
            permissions: ["p_can_view_navs"],
            content: <DailyFlowsByAccount />,
          },
          {
            value: "pending_sign_off",
            title: "PENDING SIGN OFF",
            permissions: ["p_can_view_navs"],
            content: <DailyFlowsPendingSingOff />,
          },
        ]}
      />
    </DailyFlowsContainer>
  );
}

export default DailyFlows;
